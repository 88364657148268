<template>
  <div class="toolsPage">
    <div class="box">
      <h2>Calculate Recording Capacity</h2>
      <div class="box-item">
        <div>{{ $i18n.t("tools.basis") }}</div>
        <div>
          <el-radio-group v-model="form.calculationBasis" @change="BasisChang">
            <el-radio :label="$i18n.t('securityTools.videoResolution')"></el-radio>
            <el-radio :label="$i18n.t('securityTools.encodingBitRate')"></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="box-item">
        <div>{{ $i18n.t("tools.encodingFormat") }}</div>
        <div>
          <el-radio-group v-model="form.encodingType" @change="changeTypeValue">
            <el-radio label="H.264" value="H.264"></el-radio>
            <el-radio label="H.265" value="H.265"></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="box-18" v-if="isShow">
        <div>{{ $i18n.t("tools.basisVideo") }}</div>
        <div>
          <i></i>
          <el-select class="box-sele" v-model="form.videoResolution" placeholder="1080p" @change="changeTypeValue" >
            <el-option label="1080p" value="1080p"></el-option>
            <el-option label="3MP" value="3MP"></el-option>
            <el-option label="4MP" value="4MP"></el-option>
            <el-option label="5MP" value="5MP"></el-option>
            <el-option label="6MP" value="6MP"></el-option>
            <el-option label="7MP" value="7MP"></el-option>
            <el-option label="4K" value="4K"></el-option>
          </el-select>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.basisiRate") }} (Mbit/s)</div>
        <div><input v-model="form.encodingRate" type="text"><i></i></div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.coefficient") }}</div>
        <div><input v-model="form.fluctuationCoefficient" type="text"><i></i></div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.cameras") }}</div>
        <div><input v-model="form.camerasNumber" type="text"><i></i></div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.storagePeriod") }}</div>
        <div><input v-model="form.storageTime" type="text"><i></i></div>
      </div>
      <div class="box_but">
        <button @click="calculate">{{ $i18n.t("tools.calculate") }}</button>
        <button @click="reset">{{ $i18n.t("tools.reset") }}</button>
      </div>

    </div>
    <div class="box-basao">
      <div><b>{{ $i18n.t("tools.result") }}</b><span>({{ $i18n.t("tools.forReferenceOnly") }})</span></div>
      <div><span>{{ $i18n.t("tools.capacityRequired") }}</span></div>
      <div>
        <span>{{ $i18n.t("tools.about") }}</span><b>{{form.results}}TB</b>
      </div>
      <div>
        <span>{{ $i18n.t("tools.viewFormula") }}</span>
      </div>
    </div>

  </div>
</template>

<script>
// this.$i18n.t('navbar.about')
export default {
  data () {
    return {
      form: {
        calculationBasis: 'Video Resolution',
        encodingType: 'H.264',
        videoResolution: '1080p',
        encodingRate: '4',
        fluctuationCoefficient: '1.0',
        camerasNumber: '5',
        storageTime: '30',
        results: '0',
      },
      isShow: true,
      constData: {
        'H.264': {
          '1080p': 4,
          '3MP': 6,
          '4MP': 8,
          '5MP': 10,
          '6MP': 10,
          '7MP': 12,
          '4K': 12
        },
        'H.265': {
          '1080p': 2,
          '3MP': 3,
          '4MP': 4,
          '5MP': 5,
          '6MP': 8,
          '7MP': 8,
          '4K': 8
        }
      }
    }
  },
  created () {
    this.calculate()
    console.log(111, this.form.calculationBasis)
  },
  methods: {
    BasisChang () {
      this.isShow = !this.isShow
    },
    calculate () {
      if (this.form.encodingType == 'H.264') {
        this.form.results = (this.form.camerasNumber * this.form.encodingRate * this.form.storageTime * 24 * 3600 * this.form.fluctuationCoefficient / 1024 / 1024 / 8
        ).toFixed(3)
      } else {
        this.form.results = (this.form.camerasNumber * this.form.encodingRate * this.form.storageTime * 24 * 3600 * this.form.fluctuationCoefficient / 1024 / 1024 / 8
        ).toFixed(3)
      }
      console.log(this.form);
    },
    reset () {
      this.form = {
        calculationBasis: 'Video Resolution',
        encodingType: 'H.264',
        videoResolution: '1080P',
        encodingRate: '4',
        fluctuationCoefficient: '1.0',
        camerasNumber: '5',
        storageTime: '30',
        results: '0',
      }
      this.calculate()
    },

    changeTypeValue(){
      this.form.encodingRate = this.constData[this.form.encodingType][this.form.videoResolution]
    },
  }
}
</script>

<style scoped lang="less">
/deep/ .el-input__inner {
  border: 1px solid #ebebeb;
}
.aaa {
  background: #f8f8f8;
  padding: 30px 0;
}
/deep/ .app-main[data-v-078753dd] {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  flex: 1;
  background: #f8f8f8;
}
.tools {
  width: 1410px;
  margin: 0 auto;
  height: 100%;
  display: flex;

  .bread {
    margin-left: 5.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 15px;
  }

  .toolsClassify {
    width: 20%;
    overflow: hidden;
    height: 100%;
    float: left;
    // margin-left: 4%;
    background-color: #fff;
    border-radius: 5px;
    border-right: 1px solid #f1f1f1;
    .el-menu-item.is-active {
      background-color: red !important;
    }
  }

  .toolsPage {
    width: 98%;
    height: 100%;
    float: left;
    margin-left: 2%;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.el-menu-item {
  padding: 0;
}

a:hover {
  color: blue;
}

.menuClass {
  width: 100%;
  height: 100%;
  line-height: 78px;
  display: flex;
}
/deep/ .el-menu-item {
  width: 304px;
  height: 78px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
/deep/ .el-menu-item {
  padding-left: 30px !important;
  display: flex;
}
.icon_img {
  margin: 28px 0;
  height: 48px;
  line-height: 24px;
  margin-right: 10px;
}
.menuClass_box {
  width: 220px;
  height: 48px;
  margin: 28px 0;
  font-size: 18px;

  font-weight: 400;
  color: #333333;
  white-space: normal;
  line-height: 24px;
}
span {
  font-weight: 400;
}
label {
  margin-bottom: 18px;
}
label span {
  font-size: 16px;

  font-weight: 400;
  color: #333333;
  line-height: 19px;
  margin-right: 44px;
  margin-left: 5px;
}
.box {
  width: 66%;
  min-height: 770px;
  padding-left: 62px;
  font-size: 16px;

  font-weight: 400;
  color: #666666;
  line-height: 19px;
  h2 {
    // margin-top: 20px;
    margin-bottom: 50px;
    font-size: 22px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
  }
}
.box-18 {
  margin-top: 18px;
  input {
    padding-left: 20px;
    width: 602px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e7e7e7;
    margin-top: 5px;
    outline: none;
  }
  .box-inps {
    width: 82px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e7e7e7;
  }
}
.box-sele {
  outline: none;
  width: 602px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  // border: 1px solid #e7e7e7;
  margin-top: 5px;
}
.box-item {
  margin-top: 18px;
  display: flex;
  div:nth-of-type(1) {
    width:480px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }
}
.box-basao {
  width: 340px;
  height: 290px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e7e7e7;
  padding-left: 30px;
  margin-right: 20px;
}
.box-basao div:nth-of-type(1) {
  margin: 20px 0;
  b {
    font-size: 22px;

    font-weight: 400;
    color: #000000;
    line-height: 26px;
  }
  span {
    font-size: 12px;

    font-weight: 400;
    color: #333333;
    line-height: 14px;
  }
}
.box-basao div:nth-of-type(2) {
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
  }
}
.box-basao div:nth-of-type(3) {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #c7000b;
    line-height: 19px;
  }
  b {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #c7000b;
    line-height: 47px;
  }
}
.box-basao div:nth-of-type(4) {
  margin: 20px 0;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
  }
}
.box-basao div:nth-of-type(5) {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #c7000b;
    line-height: 19px;
  }
  b {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #c7000b;
    line-height: 47px;
  }
}
.box-basao div:nth-of-type(6) {
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #333333;
    line-height: 19px;
  }
}

.box_but {
  margin-top: 60px;
  margin-bottom: 50px;
  button:nth-of-type(1) {
    width: 140px;
    height: 50px;
    background: #c7000b;
    color: #fff;
    border-radius: 54px 54px 54px 54px;
    border: 0;
    opacity: 1;
    margin-right: 20px;
  }
  button:nth-of-type(2) {
    width: 140px;
    height: 50px;
    background: #fff;
    color: #c7000b;
    border-radius: 54px 54px 54px 54px;
    border: 1px solid #c7000b;
    opacity: 1;
  }
}
.box-img {
  width: 100%;
  height: 500px;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-left: 62px;
  span {
    font-size: 22px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
    margin-bottom: 30px;
  }
  img {
    width: 769px;
    height: 423px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
}
.toolsPa {
  width: 78%;
  // height: 1259px;
  // float: left;
  margin-left: 2%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
</style>