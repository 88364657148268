<template>
  <div class="aaa">
    <div class="tools">
      <div class="toolsClassify">
        <el-row class="tac">
          <el-col :span="24">
            <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" active-text-color="#fff">
              <el-menu-item @click="index='1'">
                <div class="menuClass">
                  <i class=" icon_img">
                    <img v-if="index==1" src="../../assets/image/tools/Frame 163.png" alt="">
                    <img v-else src="../../assets/image/tools/Frame 162.png" alt="">
                  </i>
                  <div class="menuClass_box" :class="index==1? 'active' :''">
                    {{$i18n.t('securityTools.calculateBandwidth')}}
                  </div>
                </div>
              </el-menu-item>
              <el-menu-item @click="index='2' ">

                <div class="menuClass">
                  <i class=" icon_img">
                    <img v-if="index==2" src="../../assets/image/tools/Frame 159.png" alt="">
                    <img v-else src="../../assets/image/tools/Frame 138.png" alt="">
                  </i>
                  <div class="menuClass_box" :class="index==2? 'active' :''">
                    {{$i18n.t('securityTools.calculateRc')}}
                  </div>
                </div>
              </el-menu-item>
              <el-menu-item @click="index='3'">

                <div class="menuClass">
                  <i class=" icon_img">
                    <img v-if="index==3" src="../../assets/image/tools/Frame 160.png" alt="">
                    <img v-else src="../../assets/image/tools/Frame 156.png" alt="">
                  </i>
                  <div class="menuClass_box" :class="index==3? 'active' :''">
                    {{$i18n.t('securityTools.NVRAccess')}}
                  </div>
                </div>
              </el-menu-item>
              <el-menu-item @click="index='4'">
                <div class="menuClass">
                  <i class=" icon_img">
                    <img v-if="index==4" src="../../assets/image/tools/Frame 161.png" alt="">
                    <img v-else src="../../assets/image/tools/Frame 158.png" alt="">
                  </i>
                  <div class="menuClass_box" :class="index==4? 'active' :''">
                    {{$i18n.t('securityTools.cameradetectionDistance')}}
                  </div>
                </div>
              </el-menu-item>
              <el-menu-item @click="index='5'">
                <div class="menuClass">
                  <i class=" icon_img">
                    <img v-if="index==5" src="../../assets/image/tools/tools_black.svg" alt="" style="height: 22px; width: 22px;">
                    <img v-else src="../../assets/image/tools/tools_red.svg" alt="" style="height: 22px; width: 22px;">
                  </i>
                  <div class="menuClass_box" :class="index==5? 'active' :''">
                    {{$i18n.t('securityTools.ipTools')}}
                  </div>
                </div>
              </el-menu-item>
              <el-menu-item @click="index='6'">
                <div class="menuClass">
                  <i class=" icon_img">
                    <img v-if="index==6" src="../../assets/image/tools/md5_red.svg" alt="" style="height: 22px; width: 22px;">
                    <img v-else src="../../assets/image/tools/md5_black.svg" alt="" style="height: 22px; width: 22px;">
                  </i>
                  <div class="menuClass_box" :class="index==6? 'active' :''">
                    {{$i18n.t('securityTools.md5')}}
                  </div>
                </div>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="toolsPa">
        <bandwidth v-if="index==1" />
        <videoCapacity v-if="index==2" />
        <nvrAisle v-if="index==3" />
        <vidiconRange v-if="index==4" />
        <ipTools v-if="index==5" />
        <md5Checker v-if="index==6" />
      </div>
    </div>
  </div>

</template>

<script>
import bandwidth from './component/bandwidth.vue'
import videoCapacity from './component/videoCapacity.vue'
import nvrAisle from './component/nvrAisle'
import vidiconRange from './component/vidiconRange.vue'
import ipTools from './component/ipTools'
import md5Checker from './component/md5.vue'
export default {
  data () {
    return {
      index: '1',
    }
  },
  components: {
    bandwidth,
    videoCapacity,
    nvrAisle,
    vidiconRange,
    ipTools,
    md5Checker
  },
  mounted() {
    let target = this.$route.params.target;
    if (target === 'CB') this.index = 1;
    if (target === 'CRC') this.index = 2;
    if (target === 'NA') this.index = 3;
    if (target === 'CCDD') this.index = 4;
    if (target === 'IP') this.index = 5;
    if (target === 'md5') this.index = 6;
  },
  methods: {
    handleOpen (index) {
      if (index == index) {
        style = 'color: #C7000B;'
      }
    },
    handleClose () {

    },
  }
}
</script>

<style scoped lang="less">
body {
  height: 100%;
}
/deep/ .app-main {
  height: 100%;
  background: #f8f8f8 !important;
}
.aaa {
  background: #f8f8f8;
  padding: 30px 0;
}
/deep/ .app-main[data-v-078753dd] {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  flex: 1;
  background: #f8f8f8;
}
.tools {
  width: 1410px;
  margin: 0 auto;
  height: 100%;
  display: flex;

  .bread {
    margin-left: 5.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 15px;
  }

  .toolsClassify {
    width: 20%;
    overflow: hidden;
    height: 100%;
    float: left;
    // margin-left: 4%;
    background-color: #fff;
    border-radius: 5px;
    border-right: 1px solid #f1f1f1;
    .el-menu-item.is-active {
      background-color: red !important;
    }
  }

  .toolsPage {
    width: 98%;
    height: 100%;
    float: left;
    margin-left: 2%;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.el-menu-item {
  padding: 0;
}

a:hover {
  color: blue;
}

.menuClass {
  width: 100%;
  height: 100%;
  line-height: 78px;
  display: flex;
  padding-top: 15px;
}
/deep/ .el-menu-item {
  // width: 304px;
  height: 78px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
/deep/ .el-menu-item {
  // padding-left: 30px !important;
  display: flex;
}
.icon_img {
  // margin: 28px 0;
  height: 48px;
  line-height: 24px;
  margin-right: 10px;
}
.menuClass_box {
  // width: 220px;
  width: 100%;
  height: 48px;
  // margin: 28px 0;
  font-size: 18px;

  font-weight: 400;
  color: #333333;
  white-space: normal;
  line-height: 24px;
}
.active {
  color: #c7000b;
  border-right: 4px solid #c7000b;
}
span {
  font-weight: 400;
}
label {
  margin-bottom: 18px;
}
label span {
  font-size: 16px;

  font-weight: 400;
  color: #333333;
  line-height: 19px;
  margin-right: 44px;
  margin-left: 5px;
}
.box {
  padding-left: 62px;
  font-size: 16px;

  font-weight: 400;
  color: #666666;
  line-height: 19px;
  h2 {
    // margin-top: 20px;
    margin-bottom: 50px;
    font-size: 22px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
  }
}
.box-18 {
  margin-top: 18px;
  input {
    padding-left: 20px;
    width: 602px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e7e7e7;
    margin-top: 5px;
    outline: none;
  }
  .box-inps {
    width: 82px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e7e7e7;
  }
}
.box-sele {
  outline: none;
  padding-left: 20px;
  width: 602px;
  height: 36px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e7e7e7;
  margin-top: 5px;
}
.box-item {
  margin-top: 18px;
  display: flex;
  div:nth-of-type(1) {
    width: 220px;
    font-size: 16px;

    font-weight: 400;
    color: #666666;
    line-height: 19px;
    // margin-right: 138px;
  }
}
.box-basao {
  width: 340px;
  height: 300px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e7e7e7;
  padding: 0 30px;
  margin-right: 20px;
}
.box-basao div:nth-of-type(1) {
  margin: 20px 0;
  b {
    font-size: 22px;

    font-weight: 400;
    color: #000000;
    line-height: 26px;
  }
  span {
    font-size: 12px;

    font-weight: 400;
    color: #333333;
    line-height: 14px;
  }
}
.box-basao div:nth-of-type(2) {
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
  }
}
.box-basao div:nth-of-type(3) {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #c7000b;
    line-height: 19px;
  }
  b {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #c7000b;
    line-height: 47px;
  }
}
.box-basao div:nth-of-type(4) {
  margin: 20px 0;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
  }
}
.box-basao div:nth-of-type(5) {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #c7000b;
    line-height: 19px;
  }
  b {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #c7000b;
    line-height: 47px;
  }
}
.box-basao .box-cherd {
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #333333;
    line-height: 19px;
  }
}

.box_but {
  margin-top: 60px;
  button:nth-of-type(1) {
    width: 140px;
    height: 50px;
    background: #c7000b;
    color: #fff;
    border-radius: 54px 54px 54px 54px;
    border: 0;
    opacity: 1;
    margin-right: 20px;
  }
  button:nth-of-type(2) {
    width: 140px;
    height: 50px;
    background: #fff;
    color: #c7000b;
    border-radius: 54px 54px 54px 54px;
    border: 1px solid #c7000b;
    opacity: 1;
  }
}
.box-img {
  width: 100%;
  height: 500px;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-left: 62px;
  span {
    font-size: 22px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
    margin-bottom: 30px;
  }
  img {
    width: 769px;
    height: 423px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
}
.toolsPa {
  width: 78%;
  // height: 1259px;
  // float: left;
  margin-left: 2%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
</style>
