<template>
  <div style="padding-top: 20px;">
    <div class="page">
        <p class="t-h2">{{ $i18n.t("tools.md5.title1") }}</p>
        <div class="ul">
            <p class="t-h3 title">{{ $i18n.t("tools.md5.introduce1") }}<span style="color: #c7000b;">{{ $i18n.t("tools.md5.introduce2") }}</span></p>
            <ul>
                <li>
                    <div class="dot">
                        <div class="center-dot"></div>
                    </div>
                    <div class="content">{{ $i18n.t("tools.md5.small1") }}</div>
                </li>
                <li>
                    <div class="dot">
                        <div class="center-dot"></div>
                    </div>
                    <div class="content">{{ $i18n.t("tools.md5.small2") }}</div>
                </li>
                <li>
                    <div class="dot">
                        <div class="center-dot"></div>
                    </div>
                    <div class="content">{{ $i18n.t("tools.md5.small3") }}</div>
                </li>
                <li>
                    <div class="dot">
                        <div class="center-dot"></div>
                    </div>
                    <div class="content">{{ $i18n.t("tools.md5.small4") }}</div>
                </li>
            </ul>
        </div>
        <p class="t-h2">{{ $i18n.t("tools.md5.title2") }}</p>
        <p class="t-h3 title" style="margin-top: 35px;">{{ $i18n.t("tools.md5.introduce3") }}<strong>{{ $i18n.t("tools.md5.introduce4") }}</strong></p>
        <p class="t-h3 title" style="margin: 25px 0;">{{ $i18n.t("tools.md5.downloadTips") }}</p>
        <p class="t-h3" style="font-size: 16px; margin-bottom: 30px;">
            <a style="color: #c7000b;" href="http://getmd5checker.com/download" target="_blank">http://getmd5checker.com/download</a>
        </p>
        <div class="photo">
            <img src="https://resources.holowits.com.sg/tools/md5_checker.png" alt=""/>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Md5Checker',
    data() {
        return {

        }
    }
}
</script>

<style lang="less" scoped>
.page {
    z-index: 1;
    // width: 631px;
    width: 800px;
    padding-bottom: 20px;
    margin: 0 auto;
}
.t-h2 {
    margin-top: 50px;
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    // line-height: 26px;
}
.t-h3 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 18px;
}
.line {
    width: 300px;
    border-bottom: 2px solid #c7000b;
}
.ul {
    padding-left: 10px;
    padding-top: 30px;
}
.title {
    padding-bottom: 35px;
}


li {
    position: relative;
    
    .dot {
        vertical-align: middle;
        height: 28px;
        width: 28px;
        border: 3px solid #c7000b;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        .center-dot {
            background-color: #c7000b;
            width: 14px;
            height: 14px;
            border-radius: 50%;
        }
    }
    .content {
        vertical-align: middle;
        width: 640px;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 25px;
        font-weight: 600;
        display: inline-block;
    }

    &+li {
        margin-top: 20px;
    }
}

.photo {
    padding: 20px 0;
    width: 695px;
    height: 440.625px;
    img {
        width: 100%;
        height: 100%;
    }
}
.link {
    text-align: center;
    font-size: 14px;
}
</style>