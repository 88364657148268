<template>
  <div class="toolsPage">
    <div class="box">
      <h2>Calculate Camera’s Detection Distance</h2>
      <div class="box-item">
        <div>{{ $i18n.t("tools.scenario") }}</div>
        <div>
          <el-radio-group v-model="focalDis.scenario">
            <el-radio :label="1">{{$i18n.t('securityTools.microCheckpoint')}}</el-radio>
            <el-radio :label="2">{{$i18n.t('securityTools.ePoliceCheckpoint')}}</el-radio>
            <el-radio :label="3">{{$i18n.t('securityTools.targetDetection')}}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.resolutionRequirement") }}</div>
        <div class="yy"><input v-model="focalDis.pixel" type="text">
          <span v-if="focalDis.scenario=='1'">
            <i class="el-icon-question" @mouseenter="mouseShow()" @mouseleave="mouseHide()">
              <el-popover placement="top-start" width="200" trigger="hover" :content="$i18n.t('securityTools.microResolutionTips')" v-model="isShow">
              </el-popover>
            </i>
          </span>
          <span v-else-if="focalDis.scenario=='2'">
            <i class="el-icon-question" @mouseenter="mouseshow()" @mouseleave="mousehide()">
              <el-popover placement="top-start" width="200" trigger="hover" :content="$i18n.t('securityTools.ePoliceResolutionTips')"
                v-model="isHide">
              </el-popover>
            </i>
          </span>
          <span v-else>
            <i class="el-icon-question" @mouseenter="mouseEnter()" @mouseleave="mouseLeave()">
              <el-popover placement="top-start" width="200" trigger="hover"
                :content="$i18n.t('securityTools.targetResolutionTips')" v-model="isChur">
              </el-popover>
            </i>
          </span>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.sensorSize") }}</div>
        <div>
          <i></i>
          <el-select class="box-sele" v-model="focalDis.sizeKey" @change="changeSize" placeholder="">
            <el-option v-for="(item,index) in selectSize" :key="index" :label="item.sign" :value="item.sign">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.resolution") }}</div>
        <div>
          <el-select class="box-sele" v-model="focalDis.resolution" @change="changeResolution" placeholder="">
            <el-option v-for="(item,index) in ResolutionList" :key="index" :label="item" :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.focal") }}</div>
        <div><input class="box-inps" v-model="focalDis.minFocal" type="text"> <i>-</i> <input
            v-model="focalDis.maxFocal" class="box-inps" type="text"></div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.installationHeight") }}</div>
        <div class="yy"><input v-model="focalDis.installHeight" type="text">
          <span v-if="focalDis.scenario==1">
            <i class="el-icon-question" @mouseenter="mousechur()" @mouseleave="mousechurs()">
              <el-popover placement="top-start" width="200" trigger="hover" :content="$i18n.t('securityTools.microInstallationTips')" v-model="ischur">
              </el-popover>
            </i>
          </span>
          <span v-else-if="focalDis.scenario==2">
            <i class="el-icon-question" @mouseenter="mousesecet()" @mouseleave="mousesecets()">
              <el-popover placement="top-start" width="200" trigger="hover" :content="$i18n.t('securityTools.ePoliceInstallationTips')"
                v-model="issecet">
              </el-popover>
            </i>
          </span>
          <span v-else>
            <i class="el-icon-question" @mouseenter="mouseSecet()" @mouseleave="mouseSecets()">
              <el-popover placement="top-start" width="200" trigger="hover" :content="$i18n.t('securityTools.targetInstallationTips')"
                v-model="isSecet">
              </el-popover>
            </i>
          </span>
        </div>
      </div>
      <div class="box_but">
        <button @click="caculateFocal">{{ $i18n.t("tools.calculate") }}</button>
        <button @click="reset">{{ $i18n.t("tools.reset") }}</button>
      </div>
      <div class="box-img">
        <span style="margin-bottom:10px;display:inline-block;">{{ $i18n.t("tools.diagram") }}</span>
        <div>
          <img v-if="focalDis.scenario!=3" src="../../../assets/image/tools/image 77.png" alt="">
          <img v-else src="../../../assets/image/tools/shiyiTwo.png" alt="">
        </div>
      </div>
    </div>
    <div class="box-basao">
      <div><b>{{ $i18n.t("tools.reset") }}</b><span>({{ $i18n.t("tools.forReferenceOnly") }})</span></div>
      <div><span>{{ $i18n.t("tools.resultLabel1") }}</span></div>
      <div>
        <span>{{ $i18n.t("tools.about") }}</span><b>{{focalDis.startD}}m ~ {{focalDis.endD}}m</b>
      </div>
      <div><span>{{ $i18n.t("tools.tilt") }}</span></div>
      <div>
        <span>{{ $i18n.t("tools.about") }}</span><b>{{focalDis.angledStart}}° ~ {{focalDis.angleEnd}}°</b>
      </div>
      <div>
        <span>{{ $i18n.t("tools.viewFormula") }}</span>
      </div>
    </div>

  </div>
</template>
<script>
// this.$i18n.t('navbar.about')
export default {
  data () {
    return {
      isAllDataToInfo: 0,
      sizeArr: Object.freeze([
        {
          sign: '1/1.8',
          list: [{
            resolution: 200,
            unitPixel: 4
          }, {
            resolution: 300,
            unitPixel: 3.45
          }, {
            resolution: 400,
            unitPixel: 3
          }, {
            resolution: 800,
            unitPixel: 2
          }
          ]
        },
        {
          sign: '1/2.7',
          list: [{
            resolution: 200,
            unitPixel: 3
          }, {
            resolution: 500,
            unitPixel: 2
          }]
        },
        {
          sign: '1',
          list: [{
            resolution: 900,
            unitPixel: 3.45
          }]
        },
        {
          sign: '4/3',
          list: [{
            resolution: 800,
            unitPixel: 4.63
          }]
        }]),
      reseverseObj: Object.freeze({
        '200': ['1/1.8', '1/2.7'],
        '300': ['1/1.8'],
        '400': ['1/1.8'],
        '500': ['1/2.7'],
        '800': ['1/1.8', '4/3'],
        '900': ['1']
      }),
      // 选择
      selectSize: [],
      selectObj: {},
      // 传感器尺寸
      sensorSize: [],
      reverseSize: {},
      focalDis: {
        // 场景
        scenario: 1,
        // 像素要求
        pixel: 100,
        // 传感器尺寸
        sizeKey: '1/1.8',
        // 分辨率
        resolution: 200,
        minFocal: 25.00,
        maxFocal: 70.00,
        installHeight: 6.00,
        angledStart: 0,
        angleEnd: 0,
        startD: 0,
        endD: 0,
      },
      options: [{
        value: '选项1',
        label: '1080p'
      }, {
        value: '选项2',
        label: '4MP'
      }, {
        value: '选项3',
        label: '5MP'
      }, {
        value: '选项4',
        label: '6MP'
      }, {
        value: '选项5',
        label: '7MP'
      }, {
        value: '选项5',
        label: '4K'
      }
      ],
      value: '',
      // 正整数和0
      reg1: /^(0|[1-9][0-9]*)$/,
      // 大于0的数字
      reg2: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
      // 场景选型行业初始化数据
      industry: [{
        name: "请选择行业",
        key: 0
      },],
      // 场景选型使用场景初始化数据
      scene: [{
        name: "请选择使用场景",
        key: 0
      },],
      // 场景选型部署位置初始化数据
      positionData: [{
        name: "请选择部署位置",
        key: 0
      }],
      ResolutionList: [],
      isShow: false,
      isHide: false,
      isChur: false,
      ischur: false,
      issecet: false,
      isSecet: false,
      microcalorie: '微卡模式下，建议车牌的像素至少为80px。',
      ElectricalP: '电警模式下：车牌像素不小于100px。',
      bayonet: '卡口模式下：如果只需要检测车牌，车牌像素不小于100px；如还要求检测车内目标，车牌像素不小于180px。',
      targetDetection: '目标检测： 短焦款（12mm以下），推荐目标像素至少为80px；中长焦款（12mm及以上），推荐目标像素至少为120px。',
      objectIdentification: '目标识别：短焦款（12mm以下），推荐目标像素至少为110px；中长焦款（12mm及以上），推荐目标像素至少为120px。',
      laneScenario: '车道场景，建议安装高度在4.5m~6m之间；园区出入口，建议安装在1.3m~1.5m之间，可根据实际场景填写。',
      mountingHeights: '安装高度建议5.5m~ 6.5m之间。',
      detection: '目标检测：2.8mm和3.6的定焦款，建议安装高度在2m~2.5m之间; 6mm的定焦款，建议安装高度在2m-3m之间；其它焦距款型，建议安装高度在2.5m-3.5m之间。',
      recognition: '目标识别：短焦款，建议安装高度在2.5m-3m之间；长焦款，建议安装高度在2.5m-3.5m之间。'

    }
  },
  methods: {
    // 计算术语查询
    caculateSence: function () {
      this.currentPage = 1;
      this.isAllDataToInfo = 1;
      this.scenarioSelectionData = [];
      var _this = this;
      this.saveTableData.topNode && this.saveTableData.topNode[0].forEach(function (item) {
        if (_this.industryDef === item.node_id) {
          _this.infoName = item.node_name
        }
      })
      this.saveTableData.parentNode && this.saveTableData.parentNode[this.industryDef].forEach(function (item) {
        if (item.node_id === _this.sceneDef) {
          _this.sceneName = item.node_name;
        }
      })
      var dataInfo = this.saveTableData.childNode[this.sceneDef]; // 部署位置
      var equipmentType; // tittle框中展示内容
      // 未选择部署位置时
      if (this.positionDataDef === 0) {
        for (var i = 0; i < dataInfo.length; i++) {
          var titleEquipment = {}; // tittle框中展示内容
          equipmentType = dataInfo[i].economical_model && dataInfo[i].economical_model.split("\n").concat(dataInfo[i].high_end_model && dataInfo[i].high_end_model.split("\n"), dataInfo[i].recommended_model && dataInfo[i].recommended_model.split("\n"))
          titleEquipment.economical_model = dataInfo[i].economical_model && dataInfo[i].economical_model.split("\n").join("、");
          titleEquipment.high_end_model = dataInfo[i].high_end_model && dataInfo[i].high_end_model.split("\n").join("、");
          titleEquipment.recommended_model = dataInfo[i].recommended_model && dataInfo[i].recommended_model.split("\n").join("、");
          this.scenarioSelectionData.push({
            name: this.infoName,
            scene: this.sceneName,
            position: dataInfo[i].node_name,
            requirements: dataInfo[i].location_requirements,
            use: dataInfo[i].device_function,
            equipment: equipmentType,
            titleEquipment: titleEquipment,
            id: dataInfo[i].node_id
          })
        }
      } else { // 选择部署位置时
        // 找到选择的位置
        var selectName;
        this.positionData.forEach(function (item) {
          if (item.key === _this.positionDataDef) {
            selectName = item.name
          }
        })
        for (var j = 0; j < dataInfo.length; j++) {
          if (selectName === dataInfo[j].node_name) {
            equipmentType = dataInfo[j].economical_model && dataInfo[j].economical_model.split("\n").concat(dataInfo[j].high_end_model && dataInfo[j].high_end_model.split("\n"), dataInfo[j].recommended_model && dataInfo[j].recommended_model.split("\n"))
            var titleEquipmentobj = {}; // tittle框中展示内容
            titleEquipmentobj.economical_model = dataInfo[j].economical_model && dataInfo[j].economical_model.split("\n").join("、");
            titleEquipmentobj.high_end_model = dataInfo[j].high_end_model && dataInfo[j].high_end_model.split("\n").join("、");
            titleEquipmentobj.recommended_model = dataInfo[j].recommended_model && dataInfo[j].recommended_model.split("\n").join("、");
            this.scenarioSelectionData.push({
              name: this.infoName,
              scene: this.sceneName,
              position: dataInfo[j].node_name,
              requirements: dataInfo[j].location_requirements,
              use: dataInfo[j].device_function,
              equipment: equipmentType,
              titleEquipment: titleEquipmentobj,
              id: dataInfo[j].node_id
            })
          }

        }
      }
      this.total = this.scenarioSelectionData.length;
      this.totalPage = Math.ceil(this.total / 10);
      var showPageData = this.scenarioSelectionData.slice((this.currentPage - 1) * 10, this.currentPage * 10);
      this.creatTable(showPageData);
    },
    // 计算
    getResult: function () {
      this.caculateFocal();
    },
    changeScenario: function () {
      switch (this.focalDis.scenario) {
        case 1:
          this.selectSize = this.sizeArr.filter(function (item) {
            return item.sign !== '1' && item.sign !== '4/3'
          })
          this.selectObj = {
            '200': ['1/1.8', '1/2.7'],
            '300': ['1/1.8'],
            '400': ['1/1.8'],
            '500': ['1/2.7'],
            '800': ['1/1.8'],
          }
          break
        case 2:
          this.selectSize = this.sizeArr.filter(function (item) {
            return item.sign === '1/1.8' || item.sign === '1'
          })
          this.selectObj = {
            '200': ['1/1.8'],
            '300': ['1/1.8'],
            '400': ['1/1.8'],
            '800': ['1/1.8'],
            '900': ['1']
          }
          break
        case 3:
          this.selectSize = this.sizeArr.filter(function (item) {
            return item.sign !== '1'
          })
          this.selectObj = {
            '200': ['1/1.8', '1/2.7'],
            '300': ['1/1.8'],
            '400': ['1/1.8'],
            '500': ['1/2.7'],
            '800': ['1/1.8', '4/3'],
          }
          break
      }
      this.sensorSize = this.selectSize
      this.reverseSize = this.selectObj
    },
    // 重置摄像机监控距离计算
    resetFocalDis: function () {
      // 当跳转到焦距计算的时候
      this.changeScenario()
      this.focalDis = {
        // 场景
        scenario: 1,
        // 像素要求
        pixel: 100,
        // 传感器尺寸
        sizeKey: '1/1.8',
        // 分辨率
        resolution: 200,
        minFocal: 25.00,
        maxFocal: 70.00,
        installHeight: 6.00,
        angledStart: 0,
        angleEnd: 0,
        startD: 0,
        endD: 0,
      }
      this.getResult()
    },
    mouseShow () {
      this.isShow = true
    },
    mouseHide () {
      this.isShow = false
    },
    mouseshow () {
      this.isHide = true
    },
    mousehide () {
      this.isHide = false
    },
    mouseEnter () {
      this.isChur = true
    },
    mouseLeave () {
      this.isChur = false
    },

    mousechur () {
      this.ischur = true
    },
    mousechurs () {
      this.ischur = false
    },
    mousesecet () {
      this.issecet = true
    },
    mousesecets () {
      this.issecet = false
    },
    mouseSecet () {
      this.isSecet = true
    },
    mouseSecets () {
      this.isSecet = false
    },
    // 传感器尺寸发生变化
    changeSize: function () {
      var _this = this
      var size = this.focalDis.sizeKey
      this.reverseSize = {}
      Object.keys(this.selectObj).forEach(function (val) {
        if (_this.reseverseObj[val].indexOf(size) > -1) {
          _this.reverseSize[val] = _this.reseverseObj[val]
        }
      })
      this.ResolutionList = Object.keys(_this.reverseSize);
      // this.focalDis.resolution = ''
    },
    // 分辨率发生变化
    changeResolution: function () {
      var _this = this
      this.sensorSize = []
      this.selectSize.forEach(function (val) {
        val.list.some(function (item) {
          if (item.resolution == _this.focalDis.resolution) {
            _this.sensorSize.push(val)
            return true
          }
        })
      })
    },

    // 摄像机焦距计算
    caculateFocal: function () {
      console.log(this.focalDis)
      var fd = this.focalDis
      var unitPixel = null
      this.sensorSize.some(function (item) {
        if (item.sign == fd.sizeKey) {
          item.list.some(function (val) {
            if (val.resolution == fd.resolution) {
              unitPixel = val.unitPixel
              return true
            }
          })
        }
      })
      // 安装高度
      var h = fd.installHeight
      // 车牌像素 / 瞳距
      var p = fd.pixel
      // 短焦
      var m = fd.minFocal
      // 长焦
      var m2 = fd.maxFocal
      // 车牌在CMOS上宽度
      var s = p * unitPixel
      switch (fd.scenario) {
        case 1:
          var hd1 = Math.asin(h / (440 * m / s))
          var hd2 = Math.asin(h / (440 * m2 / s))
          break;
        case 2:
          var hd1 = Math.asin((h * s) / (440 * m))
          var hd2 = Math.asin((h * s) / (440 * m2))
          break;
        case 3:
          var hd1 = Math.asin((h - 1.5) / (200 * m / s))
          var hd2 = Math.asin((h - 1.5) / (200 * m2 / s))
          break
      }
      this.getFocal(hd1, hd2, m, m2, s, h)
    },
    getFocal: function (hd1, hd2, m, m2, s, h) {
      var jd1 = (180 / Math.PI * hd1).toFixed(0);
      var jd2 = (180 / Math.PI * hd2).toFixed(0);
      if (this.focalDis.scenario !== 3) {
        var d1 = ((440 * m * Math.cos(hd1)) / s).toFixed(0)
        var d2 = ((440 * m2 * Math.cos(hd2)) / s).toFixed(0)
      } else {
        var d1 = ((h - 1.5) / Math.tan(hd1)).toFixed(0)
        var d2 = ((h - 1.5) / Math.tan(hd2)).toFixed(0)
      }
      console.log(this.focalDis.angledStart, 11111111)
      this.focalDis.angledStart = Math.min(jd1, jd2)
      this.focalDis.angleEnd = Math.max(jd1, jd2)
      this.focalDis.startD = Math.min(d1, d2)
      this.focalDis.endD = Math.max(d1, d2)
    },
    // 焦距输入判断
    checkFocalDis: function (val) {
      if (val) {
        this['focalDis'][val] = this['focalDis'][val].slice(0, 10)
      }
      var checkList2 = ['pixel', 'minFocal', 'maxFocal', 'installHeight']
      this.checkClick(this['focalDis'], null, checkList2)
    },
    // 计算按钮可点击与否
    checkClick: function (self, checkList1, checkList2) {
      var that = this
      var flag1 = true
      var flag2 = true
      if (checkList1) {
        flag1 = checkList1.every(function (item) {
          return that.reg1.test(self[item])
        })
      }
      if (checkList2) {
        flag2 = checkList2.every(function (item) {
          return that.reg2.test(self[item])
        })
      }
      this.isClick = !(flag1 && flag2)
    },
    // 重置数据
    reset: function (isScene) {
      this.isClick = false
      this.isSelect = true
      this.resetFocalDis()
    },
  },
  watch: {
    // 摄像机监控距离计算场景切换
    focalScenario: function () {
      // 重新校验数据
      this.focalDis.sizeKey = '1/1.8'
      this.focalDis.resolution = 200
      this.checkFocalDis()
      this.changeScenario()
      if (!this.isClick) {
        this.getResult()
      }
    }
  },
  computed: {
    // 摄像机监控距离计算场景切换
    focalScenario: function () {
      return this.focalDis.scenario
    },
    // NVR通道接入场景切换
    caculate: function () {
      return this.nvrRoad.caculate
    },
    // 编码类型与视频分辨率监听
    codeVideo: function () {
      return this.nvrRoad.code + this.nvrRoad.video
    },
  },
  mounted () {
    this.focalDis.sizeKey = '1/1.8'
    this.focalDis.resolution = 200
    this.checkFocalDis()
    this.changeScenario()
    if (!this.isClick) {
      this.getResult()
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-input__inner {
  border: 1px solid #ebebeb;
}
.aaa {
  background: #f8f8f8;
  padding: 30px 0;
}
/deep/ .app-main[data-v-078753dd] {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  flex: 1;
  background: #f8f8f8;
}
.tools {
  width: 1410px;
  margin: 0 auto;
  height: 100%;
  display: flex;

  .bread {
    margin-left: 5.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 15px;
  }

  .toolsClassify {
    width: 20%;
    overflow: hidden;
    height: 100%;
    float: left;
    // margin-left: 4%;
    background-color: #fff;
    border-radius: 5px;
    border-right: 1px solid #f1f1f1;
    .el-menu-item.is-active {
      background-color: red !important;
    }
  }

  .toolsPage {
    width: 98%;
    height: 100%;
    float: left;
    margin-left: 2%;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.el-menu-item {
  padding: 0;
}

a:hover {
  color: blue;
}

.menuClass {
  width: 100%;
  height: 100%;
  line-height: 78px;
  display: flex;
}
/deep/ .el-menu-item {
  width: 304px;
  height: 78px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
/deep/ .el-menu-item {
  padding-left: 30px !important;
  display: flex;
}
.icon_img {
  margin: 28px 0;
  height: 48px;
  line-height: 24px;
  margin-right: 10px;
}
.menuClass_box {
  width: 220px;
  height: 48px;
  margin: 28px 0;
  font-size: 18px;

  font-weight: 400;
  color: #333333;
  white-space: normal;
  line-height: 24px;
}
span {
  font-weight: 400;
}
label {
  margin-bottom: 18px;
}
label span {
  font-size: 16px;

  font-weight: 400;
  color: #333333;
  line-height: 19px;
  margin-right: 44px;
  margin-left: 5px;
}
.box {
  width: 66%;
  padding-left: 62px;
  font-size: 16px;

  font-weight: 400;
  color: #666666;
  line-height: 19px;
  h2 {
    // margin-top: 20px;
    margin-bottom: 50px;
    font-size: 22px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
  }
}
.box-18 {
  margin-top: 18px;
  input {
    padding-left: 20px;
    width: 602px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e7e7e7;
    margin-top: 5px;
    outline: none;
  }
  .box-inps {
    width: 82px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e7e7e7;
  }
}
.box-sele {
  outline: none;
  width: 602px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  // border: 1px solid #e7e7e7;
  margin-top: 5px;
}
.box-item {
  margin-top: 18px;
  display: flex;
  div:nth-of-type(1) {
    width: 220px;
    font-size: 16px;

    font-weight: 400;
    color: #666666;
    line-height: 19px;
    // margin-right: 138px;
  }
}
.box-basao {
  width: 340px;
  height: 434px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e7e7e7;
  padding-left: 30px;
  margin-right: 20px;
}
.box-basao div:nth-of-type(1) {
  margin: 20px 0;
  b {
    font-size: 22px;

    font-weight: 400;
    color: #000000;
    line-height: 26px;
  }
  span {
    font-size: 12px;

    font-weight: 400;
    color: #333333;
    line-height: 14px;
  }
}
.box-basao div:nth-of-type(2) {
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
  }
}
.box-basao div:nth-of-type(3) {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #c7000b;
    line-height: 19px;
  }
  b {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #c7000b;
    line-height: 47px;
  }
}
.box-basao div:nth-of-type(4) {
  margin: 20px 0;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
  }
}
.box-basao div:nth-of-type(5) {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #c7000b;
    line-height: 19px;
  }
  b {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #c7000b;
    line-height: 47px;
  }
}
.box-basao div:nth-of-type(6) {
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #333333;
    line-height: 19px;
  }
}

.box_but {
  margin-top: 60px;
  margin-bottom: 50px;
  button:nth-of-type(1) {
    width: 140px;
    height: 50px;
    background: #c7000b;
    color: #fff;
    border-radius: 54px 54px 54px 54px;
    border: 0;
    opacity: 1;
    margin-right: 20px;
  }
  button:nth-of-type(2) {
    width: 140px;
    height: 50px;
    background: #fff;
    color: #c7000b;
    border-radius: 54px 54px 54px 54px;
    border: 1px solid #c7000b;
    opacity: 1;
  }
}
.box-img {
  width: 100%;
  height: 500px;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-left: 62px;
  span {
    font-size: 22px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
    margin-bottom: 30px;
  }
  img {
    width: 769px;
    height: 423px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
}
.toolsPa {
  width: 78%;
  // height: 1259px;
  // float: left;
  margin-left: 2%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.yy {
  position: relative;
}
.yyy {
  margin-left: 5px;
}
.yyys {
  padding: 19px;
  display: none;
  position: absolute;
  right: 47px;
  top: 5px;
  width: 372px;
  height: 92px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 1px rgba(123, 123, 123, 0.27);
  border-radius: 8px 0px 8px 8px;
  opacity: 1;
}
.yyy:hover .yyys {
  display: block;
}
</style>
