<template>
  <div class="toolsPage">
    <div class="box">
      <h2>NVR Access</h2>
      <div class="box-item">
        <div>{{ $i18n.t("tools.calculationObject") }}</div>
        <div>
          <el-radio-group class="disp" v-model="form.calculateObject" @change="calculateChange">
            <el-radio :label="$i18n.t('tools.obj1')"></el-radio>
            <el-radio :label="$i18n.t('tools.obj2')"></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.model") }}</div>
        <div>
          <i></i>
          <el-select class="box-sele" v-model="form.nvrModel">
            <el-option label="HWT-NVR800-A01(8CH)" value="0"></el-option>
            <el-option label="HWT-NVR800-B04(32CH)" value="1"></el-option>
            <el-option label="HWT-NVR800-A01-4P(8CH)" value="2"></el-option>
            <el-option label="HWT-NVR800-A02-8P(16CH)" value="3"></el-option>
            <el-option label="HWT-NVR800-A02-16P(16CH)" value="4"></el-option>
          </el-select>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.cameras") }}</div>
        <div><input v-model="form.camerasNumber" type="text"><i></i></div>
      </div>
      <div class="box-item">
        <div>{{ $i18n.t("tools.encodingFormat") }}</div>
        <div>
          <el-radio-group v-model="form.encodingType" @change="changeTypeValue">
            <el-radio label="H.264" value="H.264"></el-radio>
            <el-radio label="H.265" value="H.265"></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.basisVideo") }}</div>
        <div>
          <i></i>
          <el-select class="box-sele" v-model="form.videoResolution" placeholder="1080p" @change="changeTypeValue">
            <el-option label="1080P" value="1080P"></el-option>
            <el-option label="3MP" value="3MP"></el-option>
            <el-option label="4MP" value="4MP"></el-option>
            <el-option label="5MP" value="5MP"></el-option>
            <el-option label="6MP" value="6MP"></el-option>
            <el-option label="7MP" value="7MP"></el-option>
            <el-option label="4K" value="4K"></el-option>
          </el-select>
        </div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.encodingBitRate") }}</div>
        <div><input v-model="form.encodingRate" type="text"><i></i></div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.streamChannels") }}</div>
        <div><input v-model="form.drainageWay" type="text"><i></i></div>
      </div>
      <div class="box-18">
        <div>{{ $i18n.t("tools.reservedBandwidthCoefficient") }}</div>
        <div><input v-model="form.reservedBandwidth" type="text"><i></i></div>
      </div>
      <div class="box_but">
        <button @click="calculate">{{ $i18n.t("tools.calculate") }}</button>
        <button @click="reset">{{ $i18n.t("tools.reset") }}</button>
      </div>
    </div>
    <div class="box-basao" v-if="this.form.calculateObject=='Number of NVRs'">
      <div><b>{{ $i18n.t("tools.result") }}</b><span>({{ $i18n.t("tools.forReferenceOnly") }})</span></div>
      <div><span>{{ $i18n.t("tools.numberOfRrequired") }}</span></div>
      <div>
        <span>{{ $i18n.t("tools.about") }}</span><b>{{form.nvrResults}}</b>
      </div>
      <div class="box-cherd">
        <span>{{ $i18n.t("tools.viewFormula") }}</span>
      </div>
    </div>
    <div class="box-basao" v-else>
      <div><b>{{ $i18n.t("tools.result") }}</b><span>({{ $i18n.t("tools.forReferenceOnly") }})</span></div>
      <div><span>Number of accessible SDCs</span></div>
      <div>
        <span>{{ $i18n.t("tools.about") }}</span><b>{{form.sdcResults}}</b>
      </div>
      <div class="box-cherd">
        <span>{{ $i18n.t("tools.viewFormula") }}</span>
      </div>
    </div>

  </div>
</template>

<script>
// this.$i18n.t('navbar.about')
export default {
  name: 'NvrAisle',
  data () {
    return {
      options: [{
        value: '选项1',
        label: '1080p'
      }, {
        value: '选项2',
        label: '4MP'
      }, {
        value: '选项3',
        label: '5MP'
      }, {
        value: '选项4',
        label: '6MP'
      }, {
        value: '选项5',
        label: '7MP'
      }, {
        value: '选项5',
        label: '4K'
      }
      ],
      value: '',
      form: {
        calculateObject: 'Number of NVRs',
        nvrModel: '0',//NVR模型
        camerasNumber: '1',//相机号码
        nvrNumber: '1',//NVR编号
        encodingType: 'H.264',//码类型
        videoResolution: '1080P',//视频分辨率
        encodingRate: '4',//编码率
        drainageWay: '2',//排水沟
        reservedBandwidth: '1.5',//预留带宽
        nvrResults: '1',//NVR结果
        sdcResults: '6',//SDC结果
        calculationBasis: '0',
      }
    }
  },
  created () {
    this.calculate()
    console.log(111, this.form.calculationBasis)
  },
  methods: {
    BasisChang () {
      this.isShow = !this.isShow
    },
    calculate () {

      /*if (nd.caculate) {
        // 璁＄畻SDC鏁伴噺
        var cameraWidth = nd.write * nd.road * nd.scale
        nd.numSDC = Math.min(
            Math.floor(mr[nd.model].connectWidth / cameraWidth),
            mr[nd.model].connectRoad) * nd.nvrNum
      } else {
        // 璁＄畻NVR鏁伴噺
        var cameraWidthNvr = nd.num * nd.write * nd.road * nd.scale
        nd.numNvr = Math.max(
            Math.ceil(cameraWidthNvr / mr[nd.model].connectWidth),
            Math.ceil(nd.num / mr[nd.model].connectRoad))
      }*/
      if (this.form.calculateObject == 'Number of NVRs') {
        this.form.nvrResults = Math.ceil((this.form.camerasNumber * this.form.encodingRate * this.form.drainageWay * this.form.reservedBandwidth / 80), Math.ceil(this.form.camerasNumber / 8))
        console.log('nvrResults', this.form.nvrResults)
      } else {
        this.form.sdcResults = Math.floor((80 / (this.form.encodingRate * this.form.drainageWay * this.form.reservedBandwidth)), 8)
        console.log('sdcResults', this.form.sdcResults)
      }
    },
    reset () {
      this.form = {
        calculateObject: 'Number of NVRs',
        nvrModel: '0',//NVR模型
        camerasNumber: '1',//相机号码
        nvrNumber: '1',//NVR编号
        encodingType: 'H.264',//码类型
        videoResolution: '1080P',//视频分辨率
        encodingRate: '4',//编码率
        drainageWay: '2',//排水沟
        reservedBandwidth: '1.5',//预留带宽
        nvrResults: '0',//NVR结果
        sdcResults: '6',//SDC结果
      }
      this.calculate()
    },
    changeTypeValue(){
      switch (this.form.videoResolution) {
        case '1080P':
          this.form.encodingRate = this.form.encodingType === 'H.264' ? 4 : 2
          break;
        case '3MP':
        case '4MP':
        case '5MP':
          this.form.encodingRate = this.form.encodingType === 'H.264' ? 6 : 3
          break;
        case '6MP':
          this.form.encodingRate = this.form.encodingType === 'H.264' ? 10 : 8
          break;
        case '7MP':
        case '4K':
          this.form.encodingRate = this.form.encodingType === 'H.264' ? 12 : 8
          break;
        default:
          break;
      }
    },
    calculateChange (e) {
      if (e == 'Number of SDCs') {
        this.calculate()
      }
    },
  }
}
</script>

<style scoped lang="less">
.disp {
  display: flex;
}
body {
  height: 100%;
}
/deep/ .el-input__inner {
  border: 1px solid #ebebeb;
}
.aaa {
  background: #f8f8f8;
  padding: 30px 0;
}
/deep/ .app-main[data-v-078753dd] {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  flex: 1;
  background: #f8f8f8;
}
.tools {
  width: 1410px;
  margin: 0 auto;
  height: 100%;
  display: flex;

  .bread {
    margin-left: 5.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 15px;
  }

  .toolsClassify {
    width: 20%;
    overflow: hidden;
    height: 100%;
    float: left;
    // margin-left: 4%;
    background-color: #fff;
    border-radius: 5px;
    border-right: 1px solid #f1f1f1;
    .el-menu-item.is-active {
      background-color: red !important;
    }
  }

  .toolsPage {
    width: 98%;
    height: 100%;
    float: left;
    margin-left: 2%;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.el-menu-item {
  padding: 0;
}

a:hover {
  color: blue;
}

.menuClass {
  width: 100%;
  height: 100%;
  line-height: 78px;
  display: flex;
}
/deep/ .el-menu-item {
  width: 304px;
  height: 78px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
/deep/ .el-menu-item {
  padding-left: 30px !important;
  display: flex;
}
.icon_img {
  margin: 28px 0;
  height: 48px;
  line-height: 24px;
  margin-right: 10px;
}
.menuClass_box {
  width: 220px;
  height: 48px;
  margin: 28px 0;
  font-size: 18px;

  font-weight: 400;
  color: #333333;
  white-space: normal;
  line-height: 24px;
}
span {
  font-weight: 400;
}
label {
  margin-bottom: 18px;
}
label span {
  font-size: 16px;

  font-weight: 400;
  color: #333333;
  line-height: 19px;
  margin-right: 44px;
  margin-left: 5px;
}
.box {
  width: 66%;
  padding-left: 62px;
  font-size: 16px;

  font-weight: 400;
  color: #666666;
  line-height: 19px;
  h2 {
    // margin-top: 20px;
    margin-bottom: 50px;
    font-size: 22px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
  }
}
.box-18 {
  margin-top: 18px;
  input {
    padding-left: 20px;
    width: 602px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e7e7e7;
    margin-top: 5px;
    outline: none;
  }
  .box-inps {
    width: 82px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e7e7e7;
  }
}
.box-sele {
  outline: none;
  width: 602px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  // border: 1px solid #e7e7e7;
  margin-top: 5px;
}
.box-item {
  margin-top: 18px;
  display: flex;
  div:nth-of-type(1) {
    width: 190px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }
}
.box-basao {
  width: 340px;
  height: 290px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e7e7e7;
  padding-left: 30px;
  margin-right: 20px;
}
.box-basao div:nth-of-type(1) {
  margin: 20px 0;
  b {
    font-size: 22px;

    font-weight: 400;
    color: #000000;
    line-height: 26px;
  }
  span {
    font-size: 12px;

    font-weight: 400;
    color: #333333;
    line-height: 14px;
  }
}
.box-basao div:nth-of-type(2) {
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
  }
}
.box-basao div:nth-of-type(3) {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #c7000b;
    line-height: 19px;
  }
  b {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #c7000b;
    line-height: 47px;
  }
}
.box-basao div:nth-of-type(4) {
  margin: 20px 0;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
  }
}
.box-basao div:nth-of-type(5) {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #c7000b;
    line-height: 19px;
  }
  b {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #c7000b;
    line-height: 47px;
  }
}
.box-basao div:nth-of-type(6) {
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
  span {
    font-size: 16px;

    font-weight: 400;
    color: #333333;
    line-height: 19px;
  }
}

.box_but {
  margin-top: 60px;
  margin-bottom: 50px;
  button:nth-of-type(1) {
    width: 140px;
    height: 50px;
    background: #c7000b;
    color: #fff;
    border-radius: 54px 54px 54px 54px;
    border: 0;
    opacity: 1;
    margin-right: 20px;
  }
  button:nth-of-type(2) {
    width: 140px;
    height: 50px;
    background: #fff;
    color: #c7000b;
    border-radius: 54px 54px 54px 54px;
    border: 1px solid #c7000b;
    opacity: 1;
  }
}
.box-img {
  width: 100%;
  height: 500px;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-left: 62px;
  span {
    font-size: 22px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
    margin-bottom: 30px;
  }
  img {
    width: 769px;
    height: 423px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
}
.toolsPa {
  width: 78%;
  // height: 1259px;
  // float: left;
  margin-left: 2%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
</style>
